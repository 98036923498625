import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { PrevArrow, NextArrow } from '../componenets/customeArrow'; // Import custom arrows

const LoginPage = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const [ErrorMessage, setErrorMessage] = useState({})
    const topStudents = [
        { name: 'Dhatri Bhatt RMC School # 98 Grade 6 ' },
        { name: 'Mohit Patel', school: 'RMC School # 59', grade: 'Grade 5' },
        { name: 'Yusuf Hussain', school: 'RMC School # 84', grade: 'Grade 6' },
        { name: 'Bhakti Makadiya', school: 'RMC School # 69', grade: 'Grade 2' },
        { name: 'Ketki Trivedi', school: 'RMC School # 1', grade: 'Grade 3' },
        { name: 'Ojas Dodiya', school: 'RMC School # 5', grade: 'Grade 8' },
        { name: 'Mehul Ahir', school: 'RMC School # 9', grade: 'Grade 7' },
        { name: 'Tapasya Jadeja', school: 'RMC School # 94', grade: 'Grade 6' },
        { name: 'Gopal Patel', school: 'RMC School # 98', grade: 'Grade 5' },
        { name: 'Raju Mehta', school: 'RMC School # 59', grade: 'Grade 5' }
    ];
    const images = ['../images/rank1.gif',
        '../images/rank2.gif ',
        '../images/rank3.gif',
        '../images/4.gif',
        '../images/5.gif',
        '../images/6.gif',
        '../images/7.gif',
        '../images/8.gif',
        '../images/9.gif',
        '../images/10.gif',

    ];
    const validateForm = (data) => {
        let errors = {}
        if (!data.username) {
            errors.username = 'Username is required'
        }
        return errors
    }
    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        adaptiveHeight: true
    };


    const validateUsername = (value) => {
        if (value !== 'admin') {
            setError('Invalid username');
        } else {
            setError('');
            setErrorMessage('')
        }
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setUsername(value);
        validateUsername(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validateForm({ username });
        if (Object.keys(errors).length > 0) {
            setErrorMessage(errors);
        } else {
            if (username === 'admin' || username === 'user1' || username === 'user2' || username === 'user3' || username === 'user4') {
                localStorage.setItem('username', username);
                navigate('/password');
            }
        }
    };

    return (
        <Container className="d-flex justify-content-center vh-100 login-page">
            <div className="white-box">
                <img className="site-logo logoleft" src="../images/site-logo.png" alt="" />
                <img className="boy-img" src="../images/normal-img.gif" alt="" />
                <div className="w-100 white-box-inner" style={{ maxWidth: '400px' }}>
                    <h2 className="mb-3 d-flex">Login</h2>
                    <h3 className="mb-4 d-flex">Let's get started! </h3>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your username"
                                value={username}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        {ErrorMessage.username && (
                            <div className="text-danger">{ErrorMessage.username}</div>
                        )}
                        <Button variant="primary" type="submit" className="mt-5">
                            Next
                        </Button>
                        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
                    </Form>
                </div>
                <div className="top-students">
                    <h4>Top 10 Students</h4>
                    <div className='students-block'>
                        {topStudents.map((student, index) => (
                            <div className="students-name" key={index}>
                                <img className='rank-image' src={images[index]} alt={`${index + 1}`} />
                                <span className='rank'>
                                </span><strong>{student.name}</strong>
                                {/* <br /> */}
                                <small>{student.school ? `${student.school}, ${student.grade}` : ''}</small>
                            </div>
                        ))}
                    </div>
                </div>
                {/* <div className="slider-container"> */}
                {/* <Slider {...sliderSettings}>
                            {topStudents.map((student, index) => (
                                <div className='students-block' key={index}>
                                    <div className="students-name">
                                        <img className='rank-image' src={images[index % images.length]} alt={`${index + 1}`} />
                                        <div className='student-details'>
                                            <strong>{student.name}</strong><br />
                                            {student.school}<br />
                                            {student.grade}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider> */}
                {/* </div> */}
                {/* <div className='students-block'>
                        {topStudents.map((student, index) => (
                            <div className="students-name" key={index}>
                                <img className='rank-image' src={images[index]} alt={`${index + 1}`} />
                                <span className='rank'>
                                </span><strong>{student}</strong>
                            </div>
                        ))}
                    </div> */}
                <div className="announcement">
                    <h4>Announcement</h4>
                    <span>Grade 3 Result</span>
                </div>
            </div>
        </Container>
    );
};

export default LoginPage;
