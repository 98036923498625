// pages/SubjectsPage.js
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import "../App.css";
import GradeInfo from '../componenets/GradeInfo';

const SubjectsPage = () => {
    const navigate = useNavigate();
    const audioRef = useRef(null);
    const [hasInteracted, setHasInteracted] = useState(false);

    useEffect(() => {
        const username = localStorage.getItem('username');
        const password = localStorage.getItem('password');

        if (!username || !password) {
            navigate('/login');
        } else {
            // Attempt to play audio on page load
            if (audioRef.current) {
                // Try to play audio
                const playAudio = async () => {
                    try {
                        await audioRef.current.play();
                    } catch (error) {
                        console.log('Autoplay failed:', error);
                        // Autoplay failed, set interaction needed
                        setHasInteracted(true);
                    }
                };
                playAudio();
            }
        }
    }, [navigate]);

    const handleStartMusic = () => {
        if (audioRef.current) {
            audioRef.current.play().catch(error => {
                console.log('Audio playback error:', error);
            });
        }
        setHasInteracted(true); // Mark as interacted to avoid showing the button again
    };

    const handleSubjectClick = (subject) => {
        if (subject === 'maths') {
            navigate('/maths');
        } else if (subject === 'gujarati') {
            navigate('/gujarati');
        }
    };

    const Logout = () => {
        localStorage.removeItem("password");
        localStorage.removeItem("username");
        localStorage.removeItem("level1");
        localStorage.removeItem("level1Question");
        localStorage.removeItem("level1CorrectAnswers");
        localStorage.removeItem("level2");
        localStorage.removeItem("level2Question");
        localStorage.removeItem("level2CorrectAnswers");
        localStorage.removeItem("level3");
        localStorage.removeItem("level3Question");
        localStorage.removeItem("level3CorrectAnswers");
        localStorage.removeItem("result");
        navigate('/login');
    };

    return (
        <Container className="d-flex flex-column justify-content-center subject-page">
            <div className="white-box">
                <img className="site-logo logoleft" src="../images/site-logo.png" alt="" />

                <div className="loudspeaker-boy-img">
                    <img src="../images/loudspeaker.gif" alt="" />
                </div>
                <GradeInfo />
                <Button className='stop-lelevl1' onClick={() => Logout()}>
                    Logout
                </Button>
                <div className="w-100 white-box-inner">
                    <h2 className="mb-3 d-flex">કોઈ વિષય પસંદ કરો.</h2>
                    <h3 className="mb-4 d-flex">આગળ કિંમતી ખજાનો છે.</h3>
                    <p className="mb-4">આગળ વધવા માટે વિષય પસંદ કરો.</p>

                    <Button variant="primary" className="image-btn mt-3" onClick={() => handleSubjectClick('maths')}>
                        <img src="/images/math-icon.png" alt="maths" className="subject-image image-btn-1" />
                        <img src="/images/math-icon-hover.png" alt="maths" className="subject-image image-btn-2" />
                        ગણિત
                    </Button>
                </div>
            </div>
            {hasInteracted && (
                <Button variant="primary" className="start-music-btn" onClick={handleStartMusic} style={{ display: "none" }}>
                    Start Music
                </Button>
            )}
            <audio ref={audioRef} src="/sounds/welcome.mp3" loop />
        </Container>
    );
};

export default SubjectsPage;
