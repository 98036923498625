import React from 'react';

const GradeInfo = () => {
    return (
        <div className="grade-info">
            <div>Grade 3 VG 2</div>
        </div>
    );
};

export default GradeInfo;
