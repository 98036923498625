import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Row, Col, ProgressBar } from 'react-bootstrap';
import "../App.css";
import GradeInfo from '../componenets/GradeInfo';

const level1Questions = [
    {
        question: '2 + 2 = ?', options: ['3', '4', '5', '6'], correct: '4', questionText: '2 + 2 = ',
        images: ['/images/pencil-expalination.gif', '/images/pencil-expalination.gif'],
        images2: ['/images/pencil-expalination.gif', '/images/pencil-expalination.gif']
    },
    {
        question: '3 + 2 = ?', options: ['4', '6', '7', '5'], correct: '5', questionText: '3 + 2 = ',
        images: ['/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif'],
        images2: ['/images/exaplain.gif', '/images/exaplain.gif'],
    },
    {
        question: '5 + 4 = ?', options: ['7', '9', '8', '10'], correct: '9', questionText: '5 + 4 = ',
        images: ['/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif'],
        images2: ['/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif']
    },
    {
        question: '2 + 7 = ?', options: ['9', '8', '10', '6'], correct: '9', questionText: '2 + 7 = ',
        images: ['/images/exaplain.gif', '/images/exaplain.gif'],
        images2: ['/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif']
    },
    {
        question: '4 + 2 = ?', options: ['5', '6', '9', '7'], correct: '6', questionText: '4 + 2 = ',
        images: ['/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif'],
        images2: ['/images/pencil-expalination.gif', '/images/pencil-expalination.gif']
    },
    {
        question: '6 + 7 = ?', options: ['13', '12', '10', '11'], correct: '13', questionText: '6 + 7 = ',
        images: ['/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif'],
        images2: ['/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif']
    },
    {
        question: '7 + 4 = ?', options: ['9', '10', '11', '12'], correct: '11', questionText: '7 + 4 = ',
        images: ['/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif'],
        images2: ['/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif'],
    },
    {
        question: '4 + 9 = ?', options: ['10', '15', '14', '13'], correct: '13', questionText: '4 + 9 = ',
        images: ['/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif'],
        images2: ['/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif']
    },
    {
        question: '5 + 8 = ?', options: ['13', '11', '16', '15'], correct: '13', questionText: '5 + 8 = ',
        images: ['/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif'],
        images2: ['/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif']
    },
    {
        question: '3 + 8 = ?', options: ['10', '14', '11', '15'], correct: '11', questionText: '3 + 8 = ',
        images: ['/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif'],
        images2: ['/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif']
    }
];

const Mathslevel1 = () => {
    const navigate = useNavigate();

    const [userAnswers, setUserAnswers] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [questions, setQuestions] = useState([]);
    const [retryQuestions, setRetryQuestions] = useState([]);
    const [levelPassed, setLevelPassed] = useState(false);
    const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
    const [Nextbutton, setNextbutton] = useState(false);
    const [progress, setProgress] = useState(0);
    const [incorrectAnswerImages, setIncorrectAnswerImages] = useState([]);
    const [imageToDisplay, setImageToDisplay] = useState('/images/normal-img.gif');
    const [score, setScore] = useState(0);
    const [retryCount, setRetryCount] = useState(0);
    const [rightans, setrightans] = useState('');
    const [runImage, setRunImage] = useState('/images/stopscore.png');

    useEffect(() => {
        const level1Passed = localStorage.getItem('level1');
        const currentRetryCount = parseInt(localStorage.getItem('level1RetryCount'), 10) || 0;
        setRetryCount(currentRetryCount);


        // if (level1Passed !== 'true') {
        //     navigate('/maths');
        // }
        const shuffledQuestions = shuffleArray(level1Questions).slice(0, 5);

        setQuestions(shuffledQuestions);
        setProgress((1 / shuffledQuestions.length) * 100);
        setProgress(0);
        // const savedScore = parseInt(localStorage.getItem('scorelevel1'), 10) || 0;
        // setScore(savedScore);
    }, [navigate]);

    const shuffleArray = (array) => {
        let shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };

    const handleOptionClick = (option) => {
        const isCorrect = option === questions[currentQuestion].correct;
        const answer = {
            question: questions[currentQuestion].question,
            answer: option,
            correct: isCorrect
        };

        setUserAnswers((prevAnswers) => {
            const updatedAnswers = [...prevAnswers, answer];
            if (isCorrect) {
                setScore((prevScore) => {
                    const newScore = prevScore + 1;
                    localStorage.setItem('scorelevel1', newScore);
                    return newScore;
                });
            }
            if (currentQuestion === questions.length - 1) {
                const correctAnswers = updatedAnswers.filter((ans) => ans.correct).length;
                if (correctAnswers >= 3) {
                    setLevelPassed(true);
                    setImageToDisplay('/images/win-image.gif');
                    localStorage.setItem('level1', true);
                    navigate('/maths-level3');
                    localStorage.setItem('level1Question', questions.length);
                    localStorage.setItem('level1CorrectAnswers', correctAnswers);
                } else {
                    setImageToDisplay('/images/result-fail.gif');
                    setRetryQuestions(generateRetryQuestions());
                    navigate('/maths-level2');
                }
                setShowResults(true);
                setProgress(100);
                // setTimeout(() => {
                handleRetry();
                // }, 1000)

                return updatedAnswers;
            }
            return updatedAnswers;
        });
        setSelectedOption(option);
        if (isCorrect) {
            setRunImage('/images/run.gif');
            setImageToDisplay('/images/thumbs-boy-img.gif');
            setrightans('સાચો જવાબ');
            if (!showCorrectAnswer) {
                setIncorrectAnswerImages([]);
                setTimeout(() => {
                    const correctAnswerAudio = new Audio('/sounds/Next-question.wav');
                    setImageToDisplay('/images/normal-img.gif');
                    setRunImage('/images/stopscore.png');
                    setrightans('')
                    correctAnswerAudio.play()
                        .then(() => {
                            // console.log("Correct answer audio played successfully");
                        })
                        .catch((error) => {
                            console.error("Error playing correct answer audio:", error);
                        });
                    if (currentQuestion < questions.length - 1) {
                        setCurrentQuestion((prevQuestion) => {
                            const newQuestionIndex = prevQuestion + 1;
                            const progressPercentage = ((newQuestionIndex) / questions.length) * 100;
                            const nextProgress = Math.min(Math.floor(progressPercentage / 20) * 20, 100);
                            setProgress(nextProgress);
                            return newQuestionIndex;
                        });
                    }
                    else {
                        setProgress(100);
                    }
                    setSelectedOption('');
                }, 1000);
            }
        } else {
            setShowCorrectAnswer(true);
            setNextbutton(true);
            setImageToDisplay('/images/question-img.gif');
            const audio = new Audio('/sounds/Wrong-answer.mp3');
            audio.play()
                .then(() => {
                })
                .catch((error) => {
                    console.error("Error playing audio:", error);
                });
            const currentQuestionImages = questions[currentQuestion].images || [];
            const currentQuestionImages2 = questions[currentQuestion].images2 || [];
            setIncorrectAnswerImages([...currentQuestionImages, ...currentQuestionImages2]);

            if (currentQuestion < questions.length - 1) {
                setNextbutton(true);
            } else {
                setTimeout(() => {
                    setShowResults(true);
                }, 5000);
            }
        }
    };

    const handleNext = () => {
        // setImageToDisplay('/images/normal-img.gif');
        if (currentQuestion < questions.length - 1) {
            const correctAnswerAudio = new Audio('/sounds/Next-question.wav');
            correctAnswerAudio.play()
                .then(() => {
                    // console.log("Correct answer audio played successfully");
                })
                .catch((error) => {
                    console.error("Error playing correct answer audio:", error);
                });
            setCurrentQuestion((prevQuestion) => {
                const newQuestionIndex = prevQuestion + 1;
                const progressPercentage = ((newQuestionIndex) / questions.length) * 100;
                const nextProgress = Math.min(Math.floor(progressPercentage / 20) * 20, 100);
                setProgress(nextProgress);
                return newQuestionIndex;
            });
        } else {
            setProgress(100);
            setTimeout(() => {
                setShowResults(true);
            }, 5000);
        }
        setShowCorrectAnswer(false);
        setNextbutton(false);
        setIncorrectAnswerImages([]);
        setImageToDisplay('/images/normal-img.gif');
        setRunImage('/images/stopscore.png'); // Reset to default image
    };

    const calculateProgress = () => {
        // return ((currentQuestion + 1) / questions.length * 100).toFixed(0);
        return progress.toFixed(0);
    };

    const calculateResults = () => {
        const correctAnswers = userAnswers.filter((answer) => answer.correct).length;
        return { correctAnswers, wrongAnswers: userAnswers.length - correctAnswers };
    };

    const handleRetry = () => {
        const currentRetryCount = retryCount + 1;
        // localStorage.setItem('level1RetryCount', currentRetryCount);

        // if (currentRetryCount >= 1) {
        //     navigate('/maths-level2');
        // } else {
        setUserAnswers([]);
        setCurrentQuestion(0);
        setShowResults(false);
        setQuestions(retryQuestions.length ? retryQuestions : shuffleArray(level1Questions).slice(0, 5));
        setRetryQuestions([]);
        // window.location.reload();
        setProgress(0);
        setImageToDisplay('/images/normal-img.gif');
        setRunImage('/images/run.gif');
        // }

    };

    const handleProceedToLevel3 = () => {
        navigate('/maths-level2');
    };

    const generateRetryQuestions = () => {
        return shuffleArray(level1Questions).filter(
            (q) => !questions.some((prevQ) => prevQ.question === q.question)
        ).slice(0, 5);
    };

    return (
        <Container className="d-flex flex-column justify-content-center math-level-2-page">
            <div className="white-box">
                <img className="site-logo" src="../images/site-logo.png" alt="" />
                <img className="boy-img" alt="" src={imageToDisplay} />
                <ProgressBar className="progressbar-level" now={calculateProgress()} label={`${calculateProgress()}%`} />
                <GradeInfo />
                <Button className='stop-lelevl1' onClick={() => navigate('/subjects')}>બંધ કરો</Button>
                <img className="run-images" alt="" src={runImage} />
                <h3 className="score-display">
                    Run: {score}
                </h3>

                <div className="w-100 white-box-inner">
                    {showResults ? (
                        <div className="text-center">
                            {levelPassed ? (
                                <>
                                    <h2 className="mb-5">તમે Level 1 પાસ કરી લીધો છે!</h2>
                                </>
                            ) : (
                                <>
                                    <h2 className="mb-5">તમે Level 1 પાર નહિ કરી શક્યા.</h2>
                                </>
                            )}
                            {/* <h3 className="mb-4">સાચા જવાબો: {calculateResults().correctAnswers}</h3> */}
                            {/* <h3>ખોટા જવાબો: {calculateResults().wrongAnswers}</h3> */}
                        </div>
                    ) : (
                        <>
                            <h2 className="mb-4 d-flex align-items-center">
                                {questions.length > 0 ? questions[currentQuestion].question : 'Loading...'}
                            </h2>
                            {questions.length > 0 && (
                                <Row className="mt-5 pt-3 option-box">
                                    {questions[currentQuestion].options.map((option, index) => (
                                        <Col xs={6} className="d-flex align-items-center justify-content-center mb-2" key={index}>
                                            <Button
                                                variant="outline-secondary"
                                                className="w-100"
                                                onClick={() => handleOptionClick(option)}
                                            >
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <span className="me-2" style={{ fontWeight: 'bold' }}>{['A', 'B', 'C', 'D'][index]}</span>
                                                    {option}
                                                </div>
                                            </Button>
                                        </Col>
                                    ))}
                                </Row>
                            )}
                            {incorrectAnswerImages.length > 0 && (
                                <>
                                    <div className="explanation-section">
                                        <h3 className="alert alert-info explanation-heading">
                                            <strong>સમજૂતી:</strong>
                                        </h3>
                                        <div className="explanation-images">
                                            {questions[currentQuestion].images.map((image, index) => (
                                                <div className="explanation-item" key={index}>
                                                    <img className="img-thumbnail explanation-image" src={image} alt={`Image ${index + 1}`} />
                                                    <div className="explanation-number">{index + 1}</div>
                                                </div>
                                            ))}
                                            <div className="plusmain-image">
                                                <img className="imageplus-level" src="/images/plus.png" alt="Plus Sign" />
                                            </div>
                                            {questions[currentQuestion].images2.map((image, index) => (
                                                <div className="explanation-item" key={index}>
                                                    <img className="img-thumbnail explanation-image" src={image} alt={`Image ${index + 1}`} />
                                                    <div className="explanation-number">{index + 1 + questions[currentQuestion].images.length}</div>
                                                </div>
                                            ))}
                                        </div>
                                        {/* <div className="question-sectionexplain">
                                            <h4 className="question-text">{questions[currentQuestion].questionText}</h4>
                                            <h4 className="correct-answerexplain"> {questions[currentQuestion].correct}</h4>
                                        </div> */}
                                        {showCorrectAnswer && (
                                            <h4 className="alert alert-info" style={{ bottom: "-1rem" }}>
                                                <strong>
                                                    <div className="image-container">
                                                        {incorrectAnswerImages.map((imgSrc, index) => (
                                                            <img
                                                                key={index}
                                                                className="incorrect-image"
                                                                src={imgSrc}
                                                            // alt={`Incorrect answer ${index + 1}`}
                                                            />

                                                        ))}
                                                    </div>
                                                    <div className='correct-answerexplain'>સાચો જવાબ: {questions[currentQuestion].correct}</div>
                                                </strong>
                                            </h4>
                                        )}
                                    </div>
                                </>
                            )}
                            {Nextbutton && (
                                <Button className="mt-3" onClick={handleNext}>
                                    આગળ વધો </Button>
                            )}
                            <h3 className="mb-4"> {rightans} </h3>
                        </>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default Mathslevel1;
