// pages/PasswordPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import "../App.css"

const PasswordPage = () => {
    const navigate = useNavigate();
    const [answers, setAnswers] = useState({ q1: '', q2: '' });
    const [error, setError] = useState('');
    const [errorMessage, setErrorMessage] = useState({});
    const usrname = localStorage.getItem('username');

    const validateForm = (data) => {
        let errors = {};

        if (!data.q1) errors.q1 = 'Answer is required';
        if (!data.q2) errors.q2 = 'Answer is required';
        // if (!data.q3) errors.q3 = 'Answer is required';

        return errors;
    };

    const handleImageClick = (question, value) => {
        setAnswers({ ...answers, [question]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validateForm(answers);
        if (Object.keys(errors).length > 0) {
            setErrorMessage(errors);
        } else {
            const password = answers.q1 + answers.q2;
            if (password === '12' && usrname === "admin" || password === '11' && usrname === "user1" || password === '22' && usrname === "user2" || password === '33' && usrname === "user3" || password === '44' && usrname === "user4") {
                localStorage.setItem('password', password);
                navigate('/subjects');
            } else {
                setError('તમે ખોટો પાસવર્ડ દાખલ કર્યો છે');
            }
        }
    };

    const renderOptions = (question, options) => (

        <div className="d-flex justify-content-between">
            {options.map((option, index) => (
                <div key={index} onClick={() => handleImageClick(question, option.value)} className={`option ${answers[question] === option.value ? 'selected' : ''}`}>
                    <img src={option.image} alt={option.label} className="img-thumbnail" style={{ width: '100px', height: '100px' }} />
                    <div>{option.label}</div>
                </div>
            ))}
        </div>
    );

    return (
        <Container className="d-flex justify-content-center password-page">
            <div className="white-box">
            <img className="site-logo logoleft" src="../images/site-logo.png" alt="" />
                <img className="boy-img" src="../images/normal-img.gif" alt="" />
                <div className="w-100 white-box-inner" style={{ maxWidth: '400px' }}>
                    <h2 className="mb-3 d-flex">નમસ્તે {usrname}!</h2>
                    <h3 className="mb-4 d-flex">પાસવર્ડ પસંદ કરો.</h3>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            {/* <Form.Label>તમને ગમતું ચિહ્ન પસંદ કરો:</Form.Label> */}
                            {renderOptions('q1', [
                                { value: '1', image: '/images/red.jpg' },
                                { value: '2', image: '/images/yellow.png' },
                                { value: '3', image: '/images/green.png' },
                                { value: '4', image: '/images/orange.jpg' }
                            ])}
                            {errorMessage.q1 && (
                                <div className="text-danger">{errorMessage.q1}</div>
                            )}
                        </Form.Group>

                        <Form.Group className="mt-4">
                            {/* <Form.Label>તમને ગમતું ફળ પસંદ કરો:</Form.Label> */}
                            {renderOptions('q2', [
                                { value: '1', image: '/images/pink.jpg' },
                                { value: '2', image: '/images/purple.jpg' },
                                { value: '3', image: '/images/black.jpg' },
                                { value: '4', image: '/images/blue.png' }
                            ])}
                            {errorMessage.q2 && (
                                <div className="text-danger">{errorMessage.q2}</div>
                            )}
                        </Form.Group>

                        {/* <Form.Group className="mt-4"> */}
                        {/* <Form.Label>તમને ગમતો ખોરાક પસંદ કરો:</Form.Label> */}
                        {/* {renderOptions('q3', [
                            { value: '1', image: '/images/chocolate.png' },
                            { value: '2', image: '/images/cup_cake.png' },
                            { value: '3', image: '/images/pizza.png' },
                            { value: '4', image: '/images/donut.png' }
                        ])} */}
                        {/* {errorMessage.q3 && (
                            <div className="text-danger">{errorMessage.q3}</div>
                        )} */}
                        {/* </Form.Group>  */}

                        <Button variant="primary" type="submit" className="mt-5">
                            Login
                        </Button>
                        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
                    </Form>
                </div>
            </div>
        </Container>
    );
};

export default PasswordPage;
