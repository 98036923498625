// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from '../src/pages/LoginPage';
import PasswordPage from '../src/pages/PasswordPage';
import SubjectsPage from '../src/pages/SubjectsPage';
import HomePage from './pages/HomePage';
import GujaratiPage from './pages/GujaratiPage';
import EnglishPage from './pages/EnglishPage';
import Maths from './pages/Mathslevel2';
import Mathslevel2 from './pages/Mathslevel2';
import Mathslevel3 from './pages/Mathslevel3';
import Results from './pages/results';
import Mathslevel1 from './pages/Mathslevel1';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/password" element={<PasswordPage />} />
        <Route path="/subjects" element={<SubjectsPage />} />
        <Route path="/english" element={<EnglishPage />} />
        <Route path="/gujarati" element={<GujaratiPage />} />
        <Route path="/maths-level2" element={<Mathslevel2 />} />
        <Route path="/maths" element={<Mathslevel1 />} />
        <Route path="/maths-level3" element={<Mathslevel3 />} />
        <Route path="/results" element={<Results />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Router>
  );
};

export default App;
