import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Image, Button, Row, Col, Form, ProgressBar } from 'react-bootstrap';
import "../App.css";
import GradeInfo from '../componenets/GradeInfo';

const Mathslevel2 = () => {
    const navigate = useNavigate();
    const [userAnswers, setUserAnswers] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [inputError, setInputError] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [level, setLevel] = useState(1);
    const [passedLevel1, setPassedLevel1] = useState(false);
    const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
    const [showNextButton, setShowNextButton] = useState(false);
    const [imageToDisplay, setImageToDisplay] = useState('/images/normal-img.gif');
    const [incorrectAnswerImages, setIncorrectAnswerImages] = useState([]);
    const [questionAttempted, setQuestionAttempted] = useState(false);
    const [isFirstAttempt, setIsFirstAttempt] = useState(true);
    const [runImage, setRunImage] = useState('/images/stopscore.png');
    const [rightans, setrightans] = useState('');
    const [score, setScore] = useState(() => {
        const savedScore = localStorage.getItem('scorelevel1');
        return savedScore ? parseInt(savedScore, 10) : 0;
    });
    const [retryCount, setRetryCount] = useState(0);

    const questionsData = {
        1: [
            {
                word: 'મીના પાસે 2 ચોકલેટ છે અને મોહિનીએ તેને 3 ચોકલેટ આપી તો કેટલી ચોકલેટ થશે?',
                correct: '5',
                image: '/images/chocolate-5.png',
                type: 'open-ended',
                images: ['/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif'],
            },
            {
                word: 'એક ટેબલ પર ચાર દફતર પડ્યા હતા. બીજા ત્રણ દફતર બાળકો દ્વારા મૂકવામાં આવ્યા તો કુલ કેટલા દફતર થાય?',
                options: ['6', '8', '7', '5'],
                correct: '7',
                image: '/images/beg-7.png',
                type: 'multiple-choice',
                images: ['/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif'],

            },
            {
                word: 'મુકેશ પાસે 4 પેન્સિલ હતી તેણે નવી 3 પેન્સિલ ખરીદી તો તેના પાસે કુલ કેટલી પેન્સિલ થઈ હશે?',
                options: ['6', '8', '7', '5'],
                correct: '7',
                image: '/images/pencil-7-plus.png',
                type: 'multiple-choice',
                images: ['/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif',],

            },
            {
                word: 'રાજેશ પાસે 7 ક્રિકેટ બોલ છે, તેના મિત્રએ તેને 2 ક્રિકેટ બોલ આપ્યા તો રાજેશ પાસે કેટલા બોલ થશે?',
                options: ['6', '8', '9', '7'],
                correct: '9',
                type: 'multiple-choice',
                images: ['/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif',],
            },
            {
                word: 'મિથુન પાસે 7 લખોટી હતી, તેણે રમતમાં 6 લખોટી જીતી લીધી તો તેના પાસે હવે કેટલી લખોટી થઈ હશે?',
                options: ['9', '12', '13', '11'],
                correct: '13',
                type: 'multiple-choice',
                images: ['/images/pencil-expalination.gif',
                    '/images/pencil-expalination.gif',
                    '/images/pencil-expalination.gif',
                    '/images/pencil-expalination.gif',
                    '/images/pencil-expalination.gif',
                    '/images/pencil-expalination.gif',
                    '/images/pencil-expalination.gif',
                    '/images/pencil-expalination.gif',
                    '/images/pencil-expalination.gif',
                    '/images/pencil-expalination.gif',
                    '/images/pencil-expalination.gif',
                    '/images/pencil-expalination.gif',
                    , '/images/pencil-expalination.gif'],

            },
        ],
        2: [
            {
                word: 'રાજવી પાસે 9 પેન છે, તેના દાદાજીએ તેને 3 પેન લઈ આપી તો રાજવી પાસે કેટલી પેન થઈ તે જણાવો.',
                correct: '12',
                type: 'open-ended',
                images: ['/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif'],

            },
            {
                word: 'મોહિતના પાસે 9 રૂપિયા હતા, તેના ઘરે મહેમાન આવ્યા તેણે મોહિતને 10 રૂપિયા આપ્યા તો મોહિત પાસે કેટલા રૂપિયા થાય?',
                options: ['18', '17', '19', '15'],
                correct: '19',
                type: 'multiple-choice',
                images: ['/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif'],

            },
            {
                word: 'રોશની પાસે 8 લખોટી હતી, માલિનીએ 7 લખોટી રોશનીને આપી તો રોશની પાસે કેટલી લખોટી થઈ?',
                options: ['16', '14', '13', '15'],
                correct: '15',
                image: 'flowers.png',
                type: 'multiple-choice',
                images: ['/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif',],
            },
            {
                word: 'મેહુલે 6 પૂરી ખાધી. મમ્મીએ તેને વધુ 5 પૂરી આપી અને જમાંડ્યું. તો મેહુલે કુલ કેટલી પૂરી ખાધી હશે?',
                correct: '11',
                type: 'open-ended',
                images: ['/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif',],
            },
            {
                word: 'નીલમ પાસે 6 નોટબુક હતી તેના પપ્પાએ નવી 3 નોટબુક ખરીદીને આપી તો નીલમ પાસે કુલ કેટલી બુક થશે?',
                options: ['5', '9', '10', '3'],
                correct: '9',
                type: 'multiple-choice',
                images: ['/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif',],

            },

        ],
        retryLevel1: [
            {
                word: 'રાજવી પાસે 9 પેન છે, તેના દાદાજીએ તેને 3 પેન લઈ આપી તો રાજવી પાસે કેટલી પેન થઈ તે જણાવો.',
                correct: '12',
                type: 'open-ended',
                images: ['/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif',],

            },
            {
                word: 'મોહિતના પાસે 9 રૂપિયા હતા, તેના ઘરે મહેમાન આવ્યા તેણે મોહિતને 10 રૂપિયા આપ્યા તો મોહિત પાસે કેટલા રૂપિયા થાય?',
                options: ['18', '17', '19', '15'],
                correct: '19',
                type: 'multiple-choice',
                images: ['/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif', '/images/pencil-expalination.gif'],

            },
            {
                word: 'રોશની પાસે 8 લખોટી હતી, માલિનીએ 7 લખોટી રોશનીને આપી તો રોશની પાસે કેટલી લખોટી થઈ?',
                options: ['16', '14', '13', '15'],
                correct: '15',
                image: 'flowers.png',
                type: 'multiple-choice',
                images: ['/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif',],

            },
            {
                word: 'મેહુલે 6 પૂરી ખાધી. મમ્મીએ તેને વધુ 5 પૂરી આપી અને જમાંડ્યું. તો મેહુલે કુલ કેટલી પૂરી ખાધી હશે?',
                correct: '11',
                type: 'open-ended',
                images: ['/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif',],

            },
            {
                word: 'નીલમ પાસે 6 નોટબુક હતી તેના પપ્પાએ નવી 3 નોટબુક ખરીદીને આપી તો નીલમ પાસે કુલ કેટલી બુક થશે?',
                options: ['5', '9', '10', '3'],
                correct: '9',
                type: 'multiple-choice',
                images: ['/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif', '/images/exaplain.gif',],

            },

        ]
    };
    useEffect(() => {
        const savedScore = localStorage.getItem('scorelevel1');
        localStorage.setItem('scorelevel1', score);

        if (showResults) {
            const { correctAnswers } = calculateResults();
            if (level === 1) {
                localStorage.setItem('level3', JSON.stringify(true));
                localStorage.setItem('level3Question', JSON.stringify(questions.length));
                localStorage.setItem('level3CorrectAnswers', JSON.stringify(correctAnswers));
                if (correctAnswers >= 3) {
                    // setTimeout(() => {
                    navigate('/results');
                    // }, 1000); // Adding delay for the user to see the result
                } else {
                    handleContinueToLevel1(); // Automatically handle Level 1 failure
                }
            }

            // if (correctAnswers === 0) {
            // setTimeout(() => {
            // navigate('/maths');
            // }, 3000);
            // }
        }
    }, [showResults, userAnswers, level, questions.length, score]);

    const shuffleArray = (array) => {
        let shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };

    useEffect(() => {
        const username = localStorage.getItem('username');
        const password = localStorage.getItem('password');
        if (!username || !password) {
            navigate('/login');
        }
        const currentRetryCount = parseInt(localStorage.getItem('level1RetryCount'), 10) || 0;
        setRetryCount(currentRetryCount);
        const levelQuestions = level === 1 && !passedLevel1
            ? shuffleArray(questionsData[level])
            : shuffleArray(questionsData[level] || questionsData.retryLevel1);
        setQuestions(levelQuestions);
    }, [navigate, level, passedLevel1]);

    const handleOptionClick = (option) => {
        const isCorrect = option === questions[currentQuestion].correct;
        if (questionAttempted && !isFirstAttempt) return;
        setUserAnswers(prevAnswers => [
            ...prevAnswers,
            { question: questions[currentQuestion].word, answer: option, correct: isCorrect }
        ]);
        setSelectedOption(option);
        setImageToDisplay(isCorrect ? '/images/thumbs-boy-img.gif' : '/images/question-img.gif');
        setRunImage(isCorrect ? '/images/run.gif' : '/images/stopscore.png');
        setrightans(isCorrect ? 'સાચો જવાબ' : '');
        setShowCorrectAnswer(!isCorrect);
        setShowNextButton(!isCorrect);
        setQuestionAttempted(true);
        setShowNextButton(true);
        if (!isCorrect && isFirstAttempt) {
            setIncorrectAnswerImages(questions[currentQuestion].images || []);
            new Audio('/sounds/Wrong-answer.mp3').play();
            setIsFirstAttempt(false);
        } else if (isCorrect && isFirstAttempt) {
            setScore(prevScore => prevScore + 2);

            new Audio('/sounds/Next-question.wav').play();
            setTimeout(() => {
                setRunImage('/images/stopscore.png');
                setImageToDisplay('/images/normal-img.gif');
                setrightans('')
                if (currentQuestion < questions.length - 1) {
                    setCurrentQuestion(currentQuestion + 1);
                    setIsFirstAttempt(true);
                } else {
                    const totalQuestions = questionsData[level]?.length || 0; // Ensure totalQuestions is defined
                    const correctAnswers = userAnswers.filter((answer) => answer.correct).length;
                    setShowResults(true);
                    if (level === 1 && correctAnswers >= 3) {
                        setPassedLevel1(true);
                        setImageToDisplay('/images/win-image.gif');
                        localStorage.setItem('level3', JSON.stringify(true));
                        localStorage.setItem('level3Question', JSON.stringify(totalQuestions));
                        localStorage.setItem('level3CorrectAnswers', JSON.stringify(correctAnswers));
                    } else {
                        setImageToDisplay('/images/result-fail.gif');
                    }
                }
            }, 1000);
        }
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        setInputError(e.target.value.trim() === '');
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputValue.trim() === '') {
            setInputError(true);
            return;
        }
        const isCorrect = inputValue === questions[currentQuestion].correct;
        if (questionAttempted && !isFirstAttempt) return; setUserAnswers(prevAnswers => [
            ...prevAnswers,
            { question: questions[currentQuestion].word, answer: inputValue, correct: isCorrect }
        ]);
        setInputValue('');
        setInputError(false);
        setImageToDisplay(isCorrect ? '/images/thumbs-boy-img.gif' : '/images/question-img.gif');
        setRunImage(isCorrect ? '/images/run.gif' : '/images/stopscore.png');
        setrightans(isCorrect ? 'સાચો જવાબ' : '');
        setShowNextButton(true);
        setQuestionAttempted(true);
        if (isCorrect && isFirstAttempt) {
            setShowCorrectAnswer(false);
            // setScore((prevScore) => prevScore + 1);
            setScore(prevScore => prevScore + 2);

            new Audio('/sounds/Next-question.wav').play();
            setTimeout(() => {
                setRunImage('/images/stopscore.png');
                setrightans('')
                if (currentQuestion < questions.length - 1) {
                    setCurrentQuestion(currentQuestion + 1);
                    setIsFirstAttempt(true);
                } else {
                    const totalQuestions = questionsData[level]?.length || 0;
                    const correctAnswers = userAnswers.filter(answer => answer.correct).length;
                    setShowResults(true);
                    if (level === 1 && correctAnswers >= 3) {
                        setPassedLevel1(true);
                        setImageToDisplay('/images/win-image.gif');
                        localStorage.setItem('level3', JSON.stringify(true));
                        localStorage.setItem('level3Question', JSON.stringify(totalQuestions));
                        localStorage.setItem('level3CorrectAnswers', JSON.stringify(correctAnswers));
                    } else {
                        setImageToDisplay('/images/result-fail.gif');
                    }
                }
            }, 1000);
        } else {
            setShowCorrectAnswer(true);
            setIncorrectAnswerImages(questions[currentQuestion].images || []);
            new Audio('/sounds/Wrong-answer.mp3').play();
            setRunImage('/images/stopscore.png');
            setrightans('');;
            setIsFirstAttempt(false)
        }
    };

    const calculateResults = () => {
        const correctAnswers = userAnswers.filter((answer) => answer.correct).length;
        const wrongAnswers = userAnswers.length - correctAnswers;
        return { correctAnswers, wrongAnswers };
    };
    const calculateProgress = () => {
        const totalQuestions = questions.length;
        const correctAnswers = userAnswers.filter((answer, index) => answer.correct && isFirstAttempt);
        const progress = (correctAnswers.length / totalQuestions) * 100;
        return Math.min(progress, 100);
    };
    const handleContinueToLevel1 = () => {
        // const currentRetryCount = retryCount + 1;
        // localStorage.setItem('level3RetryCount', currentRetryCount);
        // if (currentRetryCount >= 1) {
        //     navigate('/maths-level2');
        // }
        setLevel(1);
        setPassedLevel1(false);
        setCurrentQuestion(0);
        setUserAnswers([]);
        navigate('/maths-level2');
        setRunImage('/images/stopscore.png');
        setrightans('');
        setShowResults(false);
        const retryQuestions = shuffleArray(questionsData.retryLevel1);
        setQuestions(questionsData.retryLevel1);
        // window.location.reload();
    };

    const handleProceedToLevel2 = () => {
        setLevel(2);
        navigate("/maths-level3")
    };
    const handleNextQuestion = () => {
        setImageToDisplay('/images/normal-img.gif');
        setRunImage('/images/stopscore.png');
        setrightans('')
        setShowNextButton(false);
        setIncorrectAnswerImages([]);
        setQuestionAttempted(true);
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
            setIsFirstAttempt(true);
            const correctAnswerAudio = new Audio('/sounds/Next-question.wav');
            setRunImage('/images/stopscore.png');
            setrightans('')
            correctAnswerAudio.play()
                .then(() => {
                    // console.log("Correct answer audio played successfully");
                })
                .catch((error) => {
                    console.error("Error playing correct answer audio:", error);
                });
        } else {
            setShowCorrectAnswer(false);
            setShowNextButton(false);
            const totalQuestions = questionsData[level]?.length || 0;
            const correctAnswers = userAnswers.filter((answer) => answer.correct).length;
            if (level === 1 && correctAnswers >= 3) {
                setPassedLevel1(true);
                setImageToDisplay('/images/thumbs-boy-img.gif');
                setRunImage('/images/run.gif');
                setrightans('સાચો જવાબ');
                localStorage.setItem('level3', JSON.stringify(true));
                localStorage.setItem('level3Question', JSON.stringify(totalQuestions));
                localStorage.setItem('level3CorrectAnswers', JSON.stringify(correctAnswers));
            } else {
                setImageToDisplay('/images/question-img.gif');
                setRunImage('/images/stopscore.png');
                setrightans('');
            }
            setShowResults(true); // Show results after all questions are answered
        }
        setSelectedOption('');
        if (currentQuestion < 4) { // 5 questions, index starts from 0
            setCurrentQuestion(currentQuestion + 1);
            setSelectedOption('');
        } else {
            setShowResults(true);
        }
    };

    return (
        <Container className="d-flex flex-column justify-content-center maths-page">
            <div className="white-box">
                <img className="site-logo" src="../images/site-logo.png" alt="" />
                <img className="boy-img" src={imageToDisplay} alt="" />
                <ProgressBar className='progressbar-level' now={calculateProgress()} label={`${calculateProgress().toFixed(0)}%`} />
                <GradeInfo />
                <Button className='stop-lelevl1' onClick={() => navigate('/subjects')}>બંધ કરો</Button>
                <img className="run-images" alt="" src={runImage} />
                <h3 className="score-display">
                    Run: {score}
                </h3>
                <div className="w-100 white-box-inner">
                    {showResults ? (
                        ""
                        // <div className="text-center">
                        //     {passedLevel1 ? (
                        //         <>
                        //             <h2 className="mb-5">તમે Level 2 પાસ કરી લીધો છે!</h2>
                        //             <Button className="mb-5" onClick={handleProceedToLevel2}>Level 3 પર જાઓ</Button>
                        //         </>
                        //     ) : (
                        //         <>
                        //             <h2 className="mb-5">તમે Level 2 પાર નહિ કરી શક્યા.</h2>
                        //             <Button className="mb-5" onClick={handleContinueToLevel1}>Level 2 પર પાછા જાઓ</Button>
                        //         </>
                        //     )}
                        //     <h3 className="mb-4">સાચા જવાબો: {calculateResults().correctAnswers}</h3>
                        //     <h3>ખોટા જવાબો: {calculateResults().wrongAnswers}</h3>
                        // </div>
                    ) : (
                        <>
                            <h2 className="mb-4 d-flex align-items-center">
                                {questions.length > 0 ? questions[currentQuestion].word : 'Loading...'}
                            </h2>
                            {questions.length > 0 && (
                                <>
                                    {questions[currentQuestion].image ? (
                                        <Image src={`${questions[currentQuestion].image}`} alt="question" className="mt-5" />
                                    ) : (
                                        ""
                                    )}
                                    {/* <Image src={`${questions[currentQuestion].image}`} alt="question" className="mt-5" /> */}
                                    {questions[currentQuestion].type === 'multiple-choice' ? (
                                        <Row className="mt-5 pt-3 option-box">
                                            {questions[currentQuestion].options.map((option, index) => (
                                                <Col xs={6} className="d-flex align-items-center justify-content-center mb-2" key={index}>
                                                    <Button
                                                        variant="outline-secondary"
                                                        className="w-100"
                                                        onClick={() => handleOptionClick(option)}
                                                    >
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <span className="me-2" style={{ fontWeight: 'bold' }}>{['A', 'B', 'C', 'D'][index]}</span>
                                                            {option}
                                                        </div>
                                                    </Button>
                                                </Col>
                                            ))}
                                        </Row>
                                    ) : (
                                        <Form className="mt-5 pt-3" onSubmit={handleSubmit}>
                                            <Form.Group controlId="answerInput">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="તમારો જવાબ અહીં લખો"
                                                    value={inputValue}
                                                    onChange={handleInputChange}
                                                    isInvalid={inputError}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    જવાબ ખાલી નહીં રહેવા દો.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Button className="mt-3" type="submit" disabled={inputError}>
                                                જવાબ મોકલો
                                            </Button>
                                        </Form>
                                    )}
                                    {/* {showCorrectAnswer && (
                                        <div className="mt-3 text-danger">
                                            <p>સાચો જવાબ: {questions[currentQuestion].correct}</p>
                                        </div>
                                    )} */}
                                    {incorrectAnswerImages.length > 0 && (
                                        <>
                                            <div className="explanation-section">
                                                <h3 className="alert alert-info explanation-heading">
                                                    <strong>સમજૂતી:</strong>
                                                </h3>
                                                <div className="explanation-images">
                                                    {questions[currentQuestion].images?.map((image, index) => (
                                                        <div className="explanation-item" key={index}>
                                                            <img className="img-thumbnail explanation-image" src={image} alt={`Image ${index + 1}`} />
                                                            <div className="explanation-number">{index + 1}</div>
                                                        </div>
                                                    ))}
                                                    {/* <div className="plusmain-image">
                                                        <img className="imageplus-level" src="/images/plus.png" alt="Plus Sign" />
                                                    </div> */}
                                                    {/* {questions[currentQuestion].images2.map((image, index) => (
                                                        <div className="explanation-item" key={index}>
                                                            <img className="img-thumbnail explanation-image" src={image} alt={`Image ${index + 1}`} />
                                                            <div className="explanation-number">{index + 1 + questions[currentQuestion].images.length}</div>
                                                        </div>
                                                    ))} */}
                                                </div>
                                                {/* <div className="question-sectionexplain">
                                            <h4 className="question-text">{questions[currentQuestion].questionText}</h4>
                                            <h4 className="correct-answerexplain"> {questions[currentQuestion].correct}</h4>
                                        </div> */}
                                                {showCorrectAnswer && (
                                                    <h4 className="alert alert-info" style={{ bottom: "-1rem" }}>
                                                        <strong>
                                                            <div className="image-container">
                                                                {questions[currentQuestion].images.map((imgSrc, index) => (
                                                                    <img
                                                                        key={index}
                                                                        className="incorrect-image"
                                                                        src={imgSrc}
                                                                    // alt={`Incorrect answer ${index + 1}`}
                                                                    />

                                                                ))}

                                                            </div>
                                                            <div className='correct-answerexplain'>સાચો જવાબ: {questions[currentQuestion].correct}</div>
                                                            <Button className="mt-3" onClick={handleNextQuestion}>
                                                                આગળ વધો
                                                            </Button>
                                                        </strong>
                                                    </h4>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    {/* {showNextButton && (
                                        <Button className="mt-3" onClick={handleNextQuestion}>
                                            આગળ વધો
                                        </Button>
                                    )} */}
                                    <h3 className="mb-4"> {rightans} </h3>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default Mathslevel2;
