import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Image, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import "../App.css";
import { useSpeechSynthesis } from 'react-speech-kit';

const GujaratiPage = () => {
    const navigate = useNavigate();
    const { speak, speaking, cancel } = useSpeechSynthesis();
    const questions = [
        {
            word: 'ટામેટું',
            options: ['1tomato.jpg', 'potato.jpg', 'cucumber.jpg', 'onion-R.jpg'],
            correct: '1tomato.jpg'
        },
        {
            word: 'કાર',
            options: ['house.png', 'car.png', 'tree.png', 'book.png'],
            correct: 'car.png'
        },
        {
            word: 'વૃક્ષ',
            options: ['book.png', 'car.png', 'tree.png', 'house.png'],
            correct: 'tree.png'
        }
    ];

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        const username = localStorage.getItem('username');
        const password = localStorage.getItem('password');

        if (!username || !password) {
            navigate('/login');
        }
    }, [navigate]);

    const handleOptionClick = (option) => {
        if (option === questions[currentQuestion].correct) {
            setError(false);
            setSelectedOption('');
            setTimeout(() => {
                if (currentQuestion < questions.length - 1) {
                    setCurrentQuestion(currentQuestion + 1);
                } else {
                    navigate('/subjects'); // Redirect to subjects page after last question
                }
            }, 500);
        } else {
            setError(true);
            setSelectedOption(option);
        }
    };

    const speakText = (text) => {
        if (speaking) {
            cancel(); // Stop any ongoing speech
        }
        speak({ text }); // Speak the text
    };

    return (
        <Container className="d-flex flex-column justify-content-center align-items-center vh-100">
            <h1 className="mb-4 d-flex align-items-center">
                <span>શબ્દ વાંચી તેના ચિત્ર પર ક્લિક કરો.</span>
                <Button variant="link" className="ms-3" onClick={() => speakText('શબ્દ વાંચી તેના ચિત્ર પર ક્લિક કરો.')}>
                    <FontAwesomeIcon icon={faVolumeUp} />
                </Button>
            </h1>
            <h2 className="mb-4 d-flex align-items-center">
                {questions[currentQuestion].word}
                <Button variant="link" className="ms-3" onClick={() => speakText(questions[currentQuestion].word)}>
                    <FontAwesomeIcon icon={faVolumeUp} />
                </Button>
            </h2>
            <div className="d-flex flex-wrap justify-content-center mt-4">
                {questions[currentQuestion].options.map((option, index) => (
                    <div
                        key={index}
                        className={`m-2 p-2 ${error && selectedOption === option ? 'border border-danger' : ''}`}
                        onClick={() => handleOptionClick(option)}
                    >
                        <Image src={`/images/${option}`} alt={option} style={{ width: '100px', height: '100px' }} />
                    </div>
                ))}
            </div>
        </Container>
    );
};

export default GujaratiPage;
