import React, { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Results = () => {
    const [percentage, setPercentage] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const level1CorrectAnswers = parseInt(localStorage.getItem('level1CorrectAnswers'), 10) || 0;
        const level1TotalQuestions = parseInt(localStorage.getItem('level1Question'), 10) || 0;
        const level2CorrectAnswers = parseInt(localStorage.getItem('level2CorrectAnswers'), 10) || 0;
        const level2TotalQuestions = parseInt(localStorage.getItem('level2Question'), 10) || 0;
        const level3CorrectAnswers = parseInt(localStorage.getItem('level3CorrectAnswers'), 10) || 0;
        const level3TotalQuestions = parseInt(localStorage.getItem('level3Question'), 10) || 0;
        // console.log("Level 1:", level1CorrectAnswers, level1TotalQuestions);
        // console.log("Level 2:", level2CorrectAnswers, level2TotalQuestions);
        // console.log("Level 3:", level3CorrectAnswers, level3TotalQuestions);
        const totalQuestions = level1TotalQuestions + level2TotalQuestions + level3TotalQuestions;
        const correctAnswers = level1CorrectAnswers + level2CorrectAnswers + level3CorrectAnswers;
        // console.log("Total Questions:", totalQuestions);
        // console.log("Correct Answers:", correctAnswers);
        const calculatedPercentage = totalQuestions > 0 ? (correctAnswers / totalQuestions) * 100 : 0;
        setPercentage(calculatedPercentage);
        // console.log("calculatedPercentage:", calculatedPercentage);
    }, []);

    const level1TotalQuestions = localStorage.getItem('level1Question') || 0;
    const level2TotalQuestions = localStorage.getItem('level2Questions') || 0;
    const level3TotalQuestions = localStorage.getItem('level3Questions') || 0;

    const level1CorrectAnswers = localStorage.getItem('level1CorrectAnswers') || 0;
    const level2CorrectAnswers = localStorage.getItem('level2CorrectAnswers') || 0;
    const level3CorrectAnswers = localStorage.getItem('level3CorrectAnswers') || 0;
    localStorage.setItem("result", percentage.toFixed(2));

    const handleStart = () => {
        localStorage.removeItem("level1")
        localStorage.removeItem("level1Question")
        localStorage.removeItem("level1CorrectAnswers")
        localStorage.removeItem("level2")
        localStorage.removeItem("level2Question")
        localStorage.removeItem("level3Question")
        localStorage.removeItem("level2CorrectAnswers")
        localStorage.removeItem("level3")
        localStorage.removeItem("calculatedPercentage")
        localStorage.removeItem("level3CorrectAnswers")
        localStorage.removeItem("result")
        navigate('/subjects')
    }

    return (
        <Container className="d-flex flex-column justify-content-center align-items-center vh-100">
            <h1>પરિણામો</h1>
            {/* <p>કુલ પ્રશ્નો: {parseInt(level1TotalQuestions, 10) + parseInt(level2TotalQuestions, 10) + parseInt(level3TotalQuestions, 10)}</p> */}
            {/* <p>સાચા જવાબો: {parseInt(level1CorrectAnswers, 10) + parseInt(level2CorrectAnswers, 10) + parseInt(level3CorrectAnswers, 10)}</p> */}
            <p>ટકાવારી: {percentage.toFixed(2)}%</p>
            <Button className="mt-3" variant="primary" onClick={handleStart}>ફરી શરૂ કરો</Button>
        </Container>
    );
};

export default Results;
