// pages/SubjectsPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import "../App.css"

const EnglishPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const username = localStorage.getItem('username');
        const password = localStorage.getItem('password');

        if (!username || !password) {
            navigate('/login');
        }
    }, [navigate]);



    return (
        <Container className="d-flex flex-column justify-content-center align-items-center vh-100">
            <h1 className="mb-4">hello.</h1>

        </Container>
    );
};

export default EnglishPage;
