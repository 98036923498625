// pages/LandingPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if username and password are in localStorage
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');
    const result = localStorage.getItem("result");

    if (!username || !password) {
      navigate('/login');
    } else {
      if (result === null) {
        navigate('/subjects');
      } else {
        navigate('/results');
      }
    }
  }, [navigate]);

  return null; // This component doesn't render anything
};

export default HomePage;